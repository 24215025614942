import { Box, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { auth } from "../../config/firebase";

interface RestrictedRouteProperties extends RouteProps {
	requiresAuth?: boolean;
	requiresGuest?: boolean;
}

const RestrictedRoute: React.FC<RestrictedRouteProperties> = ({
	requiresAuth,
	requiresGuest,
	...rest
}: RestrictedRouteProperties) => {
	const [user, loading, error] = useAuthState(auth);

	if (loading)
		return (
			<Box
				height="100vh"
				width="100vw"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<CircularProgress />
			</Box>
		);

	if (error) return <Alert severity="error">{error}</Alert>;

	if (requiresAuth && !user) return <Redirect to="/auth" />;
	if (requiresGuest && user) return <Redirect to="/" />;

	return <Route {...rest} />;
};

export default RestrictedRoute;
