import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export const Container = styled.div`
	align-items: center;
	border-right: 1px solid ${({ theme }) => theme.palette.divider};
	display: grid;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	padding: ${({ theme }) => theme.spacing(3, 0)};

	& > * {
		margin-bottom: ${({ theme }) => theme.spacing(2)}px;
	}

	& > a {
		align-items: center;
		display: flex;
		height: 48px;
		justify-content: center;
		width: 48px;

		svg {
			height: 24px;
			width: 24px;
		}
	}
`;

interface SidebarLinkProperties {
	$active?: boolean;
}

export const SidebarLink = styled(Link)<SidebarLinkProperties>`
	background-color: ${({ theme, $active }) =>
		$active ? theme.palette.primary.main : "transparent"};
	border-radius: ${({ theme }) => theme.shape.borderRadius * 4}px;
	color: ${({ theme, $active }) =>
		$active ? theme.palette.primary.contrastText : theme.palette.primary.main};
	opacity: ${({ $active }) => ($active ? 1 : 0.5)};
`;
