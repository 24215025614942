import {
	Avatar,
	Box,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useOnQuery } from "@typesaurus/react";
import React, { useMemo, useState } from "react";
import { Plus } from "react-feather";
import { HiSortDescending as SortingIcon } from "react-icons/hi";
import { Link, Route, useHistory } from "react-router-dom";
import { collection, order } from "typesaurus";

import { DatabaseUser } from "../../types/firebase/users";
import { Container, UsersFab } from "./styles";

export interface UserListProperties {
	children: JSX.Element;
}

const users = collection<DatabaseUser>("users");

const UserList: React.FC<UserListProperties> = ({
	children,
}: UserListProperties): JSX.Element => {
	const history = useHistory();
	const [usersList, { loading, error }] = useOnQuery(users, [
		order("email", "desc"),
	]);

	const listContent = useMemo(
		() =>
			usersList?.map(({ ref, data }) => (
				<ListItem key={ref.id} component={Link} to={`/users/${ref.id}`} button>
					<ListItemAvatar>
						<Avatar>
							{data.firstName[0]}
							{data.lastName?.[0] ?? ""}
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={`${data.firstName} ${data.lastName ?? ""}`}
						secondary={data.role}
					/>
				</ListItem>
			)),
		[usersList],
	);

	return (
		<>
			{loading && <LinearProgress />}
			{usersList && (
				<Container>
					<List>
						{error && (
							<Alert severity="error">Impossibile caricare gli contatti</Alert>
						)}
						<Toolbar>
							<Typography variant="h3">Utenti</Typography>
						</Toolbar>
						{listContent}
					</List>
					{children}
				</Container>
			)}
			<Route exact path="/users">
				<UsersFab
					onClick={(event) => {
						event.stopPropagation();
						history.push("/users/new");
					}}
				>
					<Plus />
					Crea Contatto
				</UsersFab>
			</Route>
		</>
	);
};

export default UserList;
