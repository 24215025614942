import { ButtonBase, Container as MuiContainer } from "@material-ui/core";
import styled from "styled-components/macro";

const Container = styled.div`
	height: calc(100vh - 64px);
	overflow: auto;
	padding-bottom: ${({ theme }) => theme.spacing(10)}px;

	main {
		padding: ${({ theme }) => theme.spacing(2, 3)};
	}

	.MuiTypography {
		&-h3 {
			margin-bottom: ${({ theme }) => theme.spacing(3)}px;
		}
	}
`;

export const Info = styled.header`
	.MuiTypography-root {
		font-size: 0.75rem;
		opacity: 0.5;
	}
`;

export const Picture = styled.img`
	border-radius: ${({ theme }) => theme.shape.borderRadius * 4}px;
	box-shadow: 0 8px 32px -24px ${({ theme }) => theme.palette.text.primary};
	/* height: 256px; */
	display: block;
	height: 320px;
	margin-bottom: ${({ theme }) => theme.spacing(4)}px;
	max-width: 100%;
	object-fit: contain;
	width: 512px;
	/* width: 100%; */
`;

export const Gallery = styled.div`
	display: grid;
	grid-gap: ${({ theme }) => theme.spacing(1)}px;
	grid-template-columns: repeat(auto-fill, minmax(80px, 2fr));
	height: 80px;

	img {
		background-color: grey;
		border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
		box-shadow: 0 12px 24px -16px;
		display: block;
		object-fit: contain;
		width: 100%;
	}
`;

export const AttachmentBox = styled(ButtonBase)`
	border: 1px solid ${({ theme }) => theme.palette.divider};
	border-radius: ${({ theme }) => theme.shape.borderRadius * 4}px;
	margin-top: ${({ theme }) => theme.spacing(1)}px;
	width: 100%;

	a {
		align-items: center;
		padding: ${({ theme }) => theme.spacing(2)}px;
		display: flex;
		justify-content: flex-start;
		width: 100%;
		height: 100%;
		color: inherit;
		text-decoration: none;
	}

	svg {
		height: 40px;
		margin-right: ${({ theme }) => theme.spacing(1)}px;
		width: 40px;
	}

	div {
		text-align: left;
	}
`;

export { Container };
