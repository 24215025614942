import { Container as MuiContainer } from "@material-ui/core";
import styled from "styled-components/macro";

const Container = styled(MuiContainer)`
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;

	img {
		display: block;
		height: 80px;
		width: 80px;
		object-fit: contain;
	}

	form {
		display: grid;
		grid-row-gap: ${({ theme }) => theme.spacing(2)}px;
		width: 100%;
	}
`;

export { Container };
