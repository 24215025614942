import {
	Avatar,
	Box,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useOnQuery } from "@typesaurus/react";
import React, { useMemo, useState } from "react";
import { Plus } from "react-feather";
import { HiSortDescending as SortingIcon } from "react-icons/hi";
import { Link, Route, useHistory } from "react-router-dom";
import { collection, order } from "typesaurus";

import { DatabaseArticle } from "../../types/firebase/articles";
import { ArticlesFab, Container } from "./styles";

export interface ArticleListProperties {
	children: JSX.Element;
}

const articles = collection<DatabaseArticle>("articles");

const ArticleList: React.FC<ArticleListProperties> = ({
	children,
}: ArticleListProperties): JSX.Element => {
	const history = useHistory();
	const [orderBy, setOrderBy] = useState("lastEdit");
	const [orderMenuAnchor, setOrderMenuAnchor] = useState<
		HTMLButtonElement | undefined
	>();
	const [articlesList, { loading, error }] = useOnQuery(articles, [
		order(orderBy, "desc"),
	]);

	const listContent = useMemo(
		() =>
			articlesList?.map(({ ref, data }) => (
				<ListItem
					key={ref.id}
					component={Link}
					to={`/articles/${ref.id}`}
					button
				>
					{data.mainPicture && (
						<ListItemAvatar>
							<Avatar src={data.mainPicture} />
						</ListItemAvatar>
					)}
					<ListItemText
						primary={data.title}
						secondary={data.plainTextBody?.slice(0, 80)}
					/>
				</ListItem>
			)),
		[articlesList],
	);

	return (
		<>
			{loading && <LinearProgress />}
			{articlesList && (
				<Container>
					<List>
						{error && (
							<Alert severity="error">Impossibile caricare gli articoli</Alert>
						)}
						<Toolbar>
							<Typography variant="h3">Articoli</Typography>
							<Box flexGrow={1} />
							<IconButton
								edge="end"
								onClick={(event) => setOrderMenuAnchor(event.currentTarget)}
							>
								<SortingIcon />
							</IconButton>
							<Menu
								anchorEl={orderMenuAnchor}
								open={Boolean(orderMenuAnchor)}
								onClose={() => setOrderMenuAnchor(undefined)}
							>
								{([
									["lastEdit", "Ultima modifica"],
									["title", "Titolo"],
								] as const).map(([property, label]) => (
									<MenuItem
										key={property}
										value={property}
										onClick={() => {
											setOrderBy(property);
											setOrderMenuAnchor(undefined);
										}}
									>
										{label}
									</MenuItem>
								))}
							</Menu>
						</Toolbar>
						{listContent}
					</List>
					{children}
				</Container>
			)}
			<Route exact path="/articles">
				<ArticlesFab
					onClick={(event) => {
						event.stopPropagation();
						history.push("/articles/new");
					}}
				>
					<Plus />
					Crea Articolo
				</ArticlesFab>
			</Route>
		</>
	);
};

export default ArticleList;
