import {
	Avatar,
	Box,
	IconButton,
	ListItem,
	ListItemText,
	Menu,
	MenuList,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { useGet } from "@typesaurus/react";
import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { collection } from "typesaurus";

import { auth } from "../../config/firebase";
import { DatabaseUser } from "../../types/firebase/users";
import Sidebar from "../Sidebar";
import {
	Container,
	DashboardAppbar,
	DashboardBody,
	SideBarContainer,
	UserMenu,
} from "./styles";

interface DashboardLayoutProperties {
	children: JSX.Element;
}

const users = collection<DatabaseUser>("users");

const DashboardLayout: React.FC<DashboardLayoutProperties> = ({
	children,
}: DashboardLayoutProperties) => {
	const [user, loading, error] = useAuthState(auth);
	const myUser: firebase.default.User = user;

	const [userMenuAnchor, setUserMenuAnchor] = useState<
		HTMLButtonElement | undefined
	>(undefined);

	return (
		<Container>
			<DashboardAppbar>
				<Toolbar>
					<Typography variant="h5">Dashboard</Typography>
					<Box flexGrow={1} />
					<IconButton
						edge="end"
						onClick={(event) => setUserMenuAnchor(event.currentTarget)}
					>
						<Avatar src={myUser.photoURL || ""}>
							{(myUser.displayName || myUser.email || " ")[0].toUpperCase()}
						</Avatar>
					</IconButton>
				</Toolbar>
			</DashboardAppbar>
			<SideBarContainer>
				<Sidebar />
			</SideBarContainer>
			<DashboardBody>{children}</DashboardBody>

			<Menu
				id="user-menu"
				open={Boolean(userMenuAnchor)}
				anchorEl={userMenuAnchor}
				onClose={() => setUserMenuAnchor(undefined)}
			>
				<UserMenu>
					<ListItem
						button
						onClick={() => {
							auth.signOut();
						}}
					>
						<ListItemText primary="Logout" />
					</ListItem>
				</UserMenu>
			</Menu>
		</Container>
	);
};

export default DashboardLayout;
