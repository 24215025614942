import {
	Box,
	Container as MuiContainer,
	IconButton,
	LinearProgress,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useGet } from "@typesaurus/react";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Delete, Edit, FileText, Trash, X } from "react-feather";
import Linkify from "react-linkify";
import { Link, useHistory, useParams } from "react-router-dom";
import { collection } from "typesaurus";

import { firestore, storage } from "../../config/firebase";
import { DatabaseArticle } from "../../types/firebase/articles";
import { AttachmentBox, Container, Info, Picture } from "./styles";

const articles = collection<DatabaseArticle>("articles");

const Article: React.FC = () => {
	const history = useHistory();
	const { articleId } = useParams<{ articleId: string }>();
	const [article, { loading, error }] = useGet(articles, articleId);

	const [attachments, setAttachments] = useState<any>();

	console.log(article?.data?.attachments);

	useEffect(() => {
		const downloadFiles = async () => {
			if (!article) return;
			if (!article.data.attachments || article.data.attachments.length === 0)
				return;
			try {
				const detailedAttachments = await Promise.all(
					article.data.attachments.map(async (attachment) => {
						const attachmentReference = await storage.refFromURL(
							attachment.src,
						);
						const attachmentMeta = await attachmentReference.getMetadata();
						const attachmentDownloadUrl = await attachmentReference.getDownloadURL();

						return {
							meta: attachmentMeta,
							downloadUrl: attachmentDownloadUrl,
						};
					}),
				);

				setAttachments(detailedAttachments);
			} catch (error) {
				console.error(error);
			}
		};

		downloadFiles();
	}, [article]);

	return (
		<Container>
			<Toolbar>
				<Box flexGrow={1} />
				<IconButton
					onClick={async () => {
						await firestore.collection("articles").doc(articleId).delete();
						history.push("/articles");
					}}
				>
					<Trash />
				</IconButton>
				<IconButton component={Link} to={`/articles/${articleId}/edit`}>
					<Edit />
				</IconButton>
				<IconButton edge="end" component={Link} to="/articles">
					<X />
				</IconButton>
			</Toolbar>
			{error && (
				<Alert severity="error">Impossibile caricare l&apos;articolo</Alert>
			)}
			{loading && <LinearProgress />}
			{article && (
				<MuiContainer>
					<Info>
						<Typography>
							{format(article.data.publishingDate, "dd MMM yyyy")}
						</Typography>
					</Info>
					<Typography variant="h3">{article.data.title}</Typography>
					{article.data.mainPicture && (
						<Picture src={article.data.mainPicture} alt="" />
					)}
					<Typography>
						<Linkify>{article.data.plainTextBody}</Linkify>
					</Typography>
					{attachments && (
						<Box mt={3}>
							<Typography variant="h5">Allegati</Typography>
							{attachments.map((attachment: any) => {
								return (
									<AttachmentBox key={attachment.downloadUrl}>
										<a
											href={attachment.downloadUrl}
											download={attachment.meta.name}
											target="_blank"
											rel="noreferrer"
										>
											<FileText />
											<div>
												<Typography>{attachment.meta.name}</Typography>
												<Typography>{attachment.meta.size}</Typography>
											</div>
										</a>
									</AttachmentBox>
								);
							})}
						</Box>
					)}
				</MuiContainer>
			)}
		</Container>
	);
};

export default Article;
