import { useGet } from "@typesaurus/react";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
	HiLocationMarker,
	HiNewspaper,
	HiPhone,
	HiUserCircle,
} from "react-icons/hi";
import { useLocation } from "react-router-dom";
import { collection } from "typesaurus";

import { auth } from "../../config/firebase";
import { DatabaseUser } from "../../types/firebase/users";
import { Container, SidebarLink } from "./styles";

const users = collection<DatabaseUser>("users");

const Sidebar = () => {
	const [user, loadingAuth, errorAuth] = useAuthState(auth);
	const location = useLocation().pathname;
	const myUser: firebase.default.User = user;
	const [profile, { loading, error }] = useGet(users, myUser.uid);

	return (
		<Container>
			<SidebarLink to="/articles" $active={location.includes("/articles")}>
				<HiNewspaper />
			</SidebarLink>
			<SidebarLink to="/places" $active={location.includes("/places")}>
				<HiLocationMarker />
			</SidebarLink>
			<SidebarLink to="/contacts" $active={location.includes("/contacts")}>
				<HiPhone />
			</SidebarLink>
			{profile?.data.role === "ADMIN" && (
				<SidebarLink to="/users" $active={location.includes("/users")}>
					<HiUserCircle />
				</SidebarLink>
			)}
		</Container>
	);
};

export default Sidebar;
