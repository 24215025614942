import {
	Avatar,
	Box,
	Chip,
	Container as MuiContainer,
	Grid,
	Icon,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useGet } from "@typesaurus/react";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Delete, Edit, FileText, Trash, X } from "react-feather";
import {
	HiGlobe,
	HiLocationMarker,
	HiOutlineGlobe,
	HiOutlineGlobeAlt,
	HiOutlineLocationMarker,
	HiOutlineMail,
	HiOutlinePhone,
	HiOutlineTag,
} from "react-icons/hi";
import { Link, useHistory, useParams } from "react-router-dom";
import { collection } from "typesaurus";

import { firestore, storage } from "../../config/firebase";
import { DatabaseUser } from "../../types/firebase/users";
import { AttachmentBox, Container, Info, Picture, UserPic } from "./styles";

const users = collection<DatabaseUser>("users");

const User: React.FC = () => {
	const history = useHistory();
	const { userId } = useParams<{ userId: string }>();
	const [user, { loading, error }] = useGet(users, userId);

	return (
		<Container>
			<Toolbar>
				<Box flexGrow={1} />
				{/* <IconButton
					onClick={async () => {
						await firestore.collection("users").doc(userId).delete();
						history.push("/users");
					}}
				>
					<Trash />
				</IconButton> */}
				<IconButton component={Link} to={`/users/${userId}/edit`}>
					<Edit />
				</IconButton>
				<IconButton edge="end" component={Link} to="/users">
					<X />
				</IconButton>
			</Toolbar>
			{error && <Alert severity="error">Impossibile caricare il luogo</Alert>}
			{loading && <LinearProgress />}
			{user && (
				<MuiContainer>
					<Box display="flex" flexDirection="row" alignItems="center">
						<UserPic>
							{user.data.firstName[0]}
							{user.data.lastName?.[0] ?? ""}
						</UserPic>
						<div>
							<Typography variant="h3">
								{user.data.firstName} {user.data.lastName}
								<Box ml={1} display="inline">
									<Chip label={user.data.role} color="primary" size="small" />
								</Box>
							</Typography>

							<ListItem disableGutters>
								<ListItemIcon>
									<Icon>
										<HiOutlineMail />
									</Icon>
								</ListItemIcon>
								<ListItemText primary={user.data.email} />
							</ListItem>
						</div>
					</Box>
				</MuiContainer>
			)}
		</Container>
	);
};

export default User;
