import { ButtonBase } from "@material-ui/core";
import styled from "styled-components/macro";

const Container = styled.div`
	height: calc(100vh - 64px);
	overflow: auto;

	.Mui {
		&Toolbar {
			&-root {
				.MuiButton-root {
					svg {
						margin-right: ${({ theme }) => theme.spacing(1)}px;
					}
				}

				.MuiFormControlLabel-root {
					border: 1px solid ${({ theme }) => theme.palette.divider};
					border-radius: ${({ theme }) => theme.shape.borderRadius}px;
					margin: 0;
					padding: ${({ theme }) => theme.spacing(0, 2, 0, 1)};
				}
			}
		}
	}

	main {
		padding: ${({ theme }) => theme.spacing(2, 3)};
	}
`;

export const AttachmentUploader = styled.div`
	border: 1px solid ${({ theme }) => theme.palette.divider};
	border-radius: ${({ theme }) => theme.shape.borderRadius}px;
	display: flex;
	flex-wrap: wrap;
	padding: ${({ theme }) => theme.spacing(2)}px;

	& > figure {
		width: 100%;
		margin: 0;
		display: grid;
		grid-gap: 16px;
		grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
	}

	& > article {
		width: 100%;
	}

	figure,
	article {
		& > button {
			width: 100%;
			border-radius: ${({ theme }) => theme.shape.borderRadius}px;
			overflow: hidden;

			& > img {
				background-color: ${({ theme }) => theme.palette.background.paper};
				display: block;
				height: 128px;
				object-fit: contain;
				width: 100%;
				border: 1px solid ${({ theme }) => theme.palette.divider};
			}

			& > div {
				background-color: rgba(0, 0, 0, 0.25);
				height: 100%;
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				opacity: 0;
				transition: 150ms;

				svg {
					height: 2rem;
					width: 2rem;
				}
			}

			&:hover {
				div {
					opacity: 1;
				}
			}
		}
	}

	.MuiButton-root {
		margin-top: ${({ theme }) => theme.spacing(2)}px;
	}
`;

export const AttachmentBox = styled(ButtonBase)`
	background-color: green;
	border: 1px solid ${({ theme }) => theme.palette.divider};
	border-radius: ${({ theme }) => theme.shape.borderRadius}px;
	margin-top: ${({ theme }) => theme.spacing(1)}px;
	width: 100%;

	& > a,
	& > span {
		align-items: center;
		background-color: red;
		color: inherit;
		display: flex;
		height: 100%;
		justify-content: flex-start;
		padding: ${({ theme }) => theme.spacing(2)}px;
		text-decoration: none;
		width: 100%;
	}

	svg {
		height: 40px;
		margin-right: ${({ theme }) => theme.spacing(1)}px;
		width: 40px;
	}

	div {
		text-align: left;
	}
`;

export { Container };
