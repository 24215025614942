import {
	Avatar,
	Box,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useOnQuery } from "@typesaurus/react";
import React, { useMemo, useState } from "react";
import { Plus } from "react-feather";
import { HiSortDescending as SortingIcon } from "react-icons/hi";
import { Link, Route, useHistory } from "react-router-dom";
import { collection, order } from "typesaurus";

import { DatabasePlace } from "../../types/firebase/places";
import { Container, PlacesFab } from "./styles";

export interface PlaceListProperties {
	children: JSX.Element;
}

const places = collection<DatabasePlace>("places");

const PlaceList: React.FC<PlaceListProperties> = ({
	children,
}: PlaceListProperties): JSX.Element => {
	const history = useHistory();
	const [orderBy, setOrderBy] = useState("name");
	const [orderMenuAnchor, setOrderMenuAnchor] = useState<
		HTMLButtonElement | undefined
	>();
	const [placesList, { loading, error }] = useOnQuery(places, [
		order(orderBy, "desc"),
	]);

	const listContent = useMemo(
		() =>
			placesList?.map(({ ref, data }) => (
				<ListItem key={ref.id} component={Link} to={`/places/${ref.id}`} button>
					<ListItemText primary={data.name} secondary={data.address} />
				</ListItem>
			)),
		[placesList],
	);

	return (
		<>
			{loading && <LinearProgress />}
			{placesList && (
				<Container>
					<List>
						{error && (
							<Alert severity="error">Impossibile caricare gli articoli</Alert>
						)}
						<Toolbar>
							<Typography variant="h3">Luoghi</Typography>
							<Box flexGrow={1} />
							<IconButton
								edge="end"
								onClick={(event) => setOrderMenuAnchor(event.currentTarget)}
							>
								<SortingIcon />
							</IconButton>
							<Menu
								anchorEl={orderMenuAnchor}
								open={Boolean(orderMenuAnchor)}
								onClose={() => setOrderMenuAnchor(undefined)}
							>
								{([
									["name", "Nome"],
									["type", "Tipo"],
									["title", "Indirizzo"],
								] as const).map(([property, label]) => (
									<MenuItem
										key={property}
										value={property}
										onClick={() => {
											setOrderBy(property);
											setOrderMenuAnchor(undefined);
										}}
									>
										{label}
									</MenuItem>
								))}
							</Menu>
						</Toolbar>
						{listContent}
					</List>
					{children}
				</Container>
			)}
			<Route exact path="/places">
				<PlacesFab
					onClick={(event) => {
						event.stopPropagation();
						history.push("/places/new");
					}}
				>
					<Plus />
					Crea Luogo
				</PlacesFab>
			</Route>
		</>
	);
};

export default PlaceList;
