import { ButtonBase } from "@material-ui/core";
import styled from "styled-components/macro";

const Container = styled.div`
	height: calc(100vh - 64px);
	overflow: auto;

	.Mui {
		&Toolbar {
			&-root {
				.MuiButton-root {
					svg {
						margin-right: ${({ theme }) => theme.spacing(1)}px;
					}
				}

				.MuiFormControlLabel-root {
					border: 1px solid ${({ theme }) => theme.palette.divider};
					border-radius: ${({ theme }) => theme.shape.borderRadius}px;
					margin: 0;
					padding: ${({ theme }) => theme.spacing(0, 2, 0, 1)};
				}
			}
		}
	}

	main {
		padding: ${({ theme }) => theme.spacing(2, 3)};
	}
`;

export const AttachmentUploader = styled.div`
	border: 1px solid ${({ theme }) => theme.palette.divider};
	border-radius: ${({ theme }) => theme.shape.borderRadius}px;
	display: flex;
	flex-wrap: wrap;
	padding: ${({ theme }) => theme.spacing(2)}px;

	img {
		background-color: ${({ theme }) => theme.palette.background.paper};
		display: block;
		height: 128px;
		object-fit: contain;
		width: 128px;
		border-radius: ${({ theme }) => theme.shape.borderRadius}px;
		border: 1px solid ${({ theme }) => theme.palette.divider};

		&:first-child {
			flex-grow: 1;
			height: auto;
			width: 100%;
			margin: 0 auto;
		}

		&:not(:first-child) {
			margin: ${({ theme }) => theme.spacing(2, 2, 0)};
		}
	}

	div {
		width: 100%;
	}

	.MuiButton-root {
		margin-top: ${({ theme }) => theme.spacing(2)}px;
	}
`;

export const AttachmentBox = styled(ButtonBase)`
	border: 1px solid ${({ theme }) => theme.palette.divider};
	border-radius: ${({ theme }) => theme.shape.borderRadius}px;
	margin-top: ${({ theme }) => theme.spacing(1)}px;
	width: 100%;

	a {
		align-items: center;
		padding: ${({ theme }) => theme.spacing(2)}px;
		display: flex;
		justify-content: flex-start;
		width: 100%;
		height: 100%;
		color: inherit;
		text-decoration: none;
	}

	svg {
		height: 40px;
		margin-right: ${({ theme }) => theme.spacing(1)}px;
		width: 40px;
	}

	div {
		text-align: left;
	}
`;

export { Container };
