import { DevTool } from "@hookform/devtools";
import { Button, TextField } from "@material-ui/core";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import logo from "../../assets/icons/logo.png";
import { auth } from "../../config/firebase";
import { Container } from "./styles";

interface Credentials {
	email: string;
	password: string;
}

const Auth: React.FC = () => {
	const { register, control, reset, handleSubmit, errors, formState } = useForm<
		Credentials
	>();

	const onSubmit: SubmitHandler<Credentials> = async (data) => {
		auth.signInWithEmailAndPassword(data.email, data.password);
	};

	return (
		<Container maxWidth="xs">
			<form onSubmit={handleSubmit(onSubmit)}>
				<img src={logo} alt="logo" />
				<TextField
					inputRef={register}
					name="email"
					label="Email"
					variant="outlined"
					type="email"
					fullWidth
				/>
				<TextField
					inputRef={register}
					name="password"
					label="Password"
					variant="outlined"
					type="password"
					fullWidth
				/>
				<Button variant="contained" color="primary" type="submit" size="large">
					Accedi
				</Button>
			</form>
			<DevTool control={control} />
		</Container>
	);
};

export default Auth;
