import { DevTool } from "@hookform/devtools";
import {
	Box,
	Button,
	ButtonBase,
	FormControl,
	FormControlLabel,
	Grid,
	Hidden,
	IconButton,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	Switch,
	TextField,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useGet } from "@typesaurus/react";
import { format } from "date-fns";
import { da } from "date-fns/esm/locale";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { ArrowLeft, FileText, Save, Trash } from "react-feather";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { collection } from "typesaurus";

import { firebase, firestore, GeoPoint, storage } from "../../config/firebase";
import { DatabasePlace, LocalPlace } from "../../types/firebase/places";
import { AttachmentUploader, Container } from "../ArticleEditor/styles";
import { AttachmentBox } from "../Place/styles";

const categories = ["poi", "health", "holy"] as const;

const places = collection<DatabasePlace>("places");

const PlaceEditor = (): JSX.Element => {
	const history = useHistory();
	const { placeId } = useParams<{ placeId: string }>();
	const [place, { loading, error }] = useGet(places, placeId);
	const {
		register,
		control,
		reset,
		handleSubmit,
		errors,
		formState,
		getValues,
		setValue,
	} = useForm<LocalPlace>({
		defaultValues: {
			name: place?.data.name,
			type: place?.data.type,
			address: place?.data.address,
			coords: {
				lat: place?.data.coords?.T_ ?? "",
				lon: place?.data.coords?.w_ ?? "",
			},
			phone: place?.data.phone ?? "",
			email: place?.data.email ?? "",
			website: place?.data.website ?? "",
			notes: place?.data.notes ?? "",
			gallery: place?.data.gallery ?? [],
		},
	});

	const values = getValues();

	const [isUploadingGallery, setIsUploadingGallery] = useState(false);

	useEffect(() => {
		reset({
			name: place?.data.name,
			type: place?.data.type,
			address: place?.data.address,
			coords: {
				lat: place?.data.coords?.T_ ?? "",
				lon: place?.data.coords?.w_ ?? "",
			},
			phone: place?.data.phone ?? "",
			email: place?.data.email ?? "",
			website: place?.data.website ?? "",
			notes: place?.data.notes ?? "",
			gallery: place?.data.gallery ?? [],
		});
	}, [place, loading, reset]);

	const uploadFile = (file: File) => {
		const storageReference = storage
			.ref()
			.child(
				`/places/gallery/${format(new Date(), "yyyyMMdd")}/${format(
					new Date(),
					"HHmm",
				)}${file.name}`,
			);
		return storageReference.put(file);
	};

	const uploadFiles = async (files: File[]) => {
		setIsUploadingGallery(true);

		const fileReferences = await Promise.all(
			files.map((file) => uploadFile(file)),
		);

		fileReferences.map(async (fileReference) => {
			const fileUrl = await fileReference.ref.getDownloadURL();
			setValue("gallery", [...(getValues().gallery ?? []), fileUrl]);
		});

		setIsUploadingGallery(false);
	};

	const isEditing = Boolean(placeId);

	const onSubmit: SubmitHandler<LocalPlace> = async (data) => {
		if (!data.name) return;
		if (!data.type) return;
		if (!data.address) return;
		if (!data.coords?.lat) return;
		if (!data.coords?.lon) return;
		if (!data.coords) return;

		const placePayload: DatabasePlace = {
			name: data.name,
			type: data.type,
			address: data.address,
			coords: new GeoPoint(
				Number.parseFloat(data.coords.lat),
				Number.parseFloat(data.coords.lon),
			),
			phone: data.phone,
			email: data.email,
			website: data.website,
			notes: data.notes,
			gallery: data.gallery || [],
		};

		if (isEditing) {
			firestore.collection("places").doc(placeId).update(placePayload);
			history.push(`/places/${placeId}`);
		} else {
			const result = await firestore.collection("places").add(placePayload);
			history.push(result.id);
		}
	};

	if (isEditing && loading)
		return (
			<Container>
				<LinearProgress />
			</Container>
		);

	if (isEditing && error)
		return (
			<Container>
				<Alert severity="error">Impossibile caricare l&apos;articolo</Alert>
			</Container>
		);

	return (
		<Container>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Toolbar>
					<Box marginRight={1}>
						<IconButton
							edge="start"
							component={Link}
							to={placeId ? `/places/${placeId}` : "/places"}
						>
							<ArrowLeft />
						</IconButton>
					</Box>
					<FormControlLabel
						control={
							<Controller
								control={control}
								name="isPublished"
								render={({ onChange, onBlur, value, ref }) => (
									<Switch
										color="primary"
										onBlur={onBlur}
										onChange={(event: ChangeEvent<HTMLInputElement>) =>
											onChange(event.target.checked)
										}
										checked={value}
										inputRef={ref}
									/>
								)}
							/>
						}
						label="Pubblico"
					/>
					<Box flexGrow={1} />
					<Button
						type="submit"
						variant="outlined"
						disabled={!formState.isDirty}
					>
						<Save />
						<Hidden mdDown>Salva modifiche</Hidden>
					</Button>
				</Toolbar>
				{formState.isDirty && (
					<Alert severity="warning">Ci sono delle modifiche da salvare.</Alert>
				)}
				<main>
					<Grid container spacing={2}>
						<Grid item xs={12} md={9}>
							<TextField
								inputRef={register}
								name="name"
								label="Nome"
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={6} md={3}>
							<FormControl variant="outlined" fullWidth>
								<InputLabel id="Categoria">Categoria</InputLabel>
								<Controller
									as={
										<Select labelId="Categoria" label="Categoria">
											{categories.map((category) => (
												<MenuItem key={category} value={category}>
													{category}
												</MenuItem>
											))}
										</Select>
									}
									name="type"
									control={control}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={12}>
							<TextField
								inputRef={register}
								name="address"
								label="Indirizzo"
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								inputRef={register}
								name="coords.lat"
								label="Latitudine"
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								inputRef={register}
								name="coords.lon"
								label="Longitudine"
								variant="outlined"
								fullWidth
							/>
						</Grid>

						<Grid item xs={12} md={4}>
							<TextField
								inputRef={register}
								name="phone"
								label="Telefono"
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField
								inputRef={register}
								name="email"
								label="Email"
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<TextField
								inputRef={register}
								name="website"
								label="Sito Web"
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								inputRef={register}
								name="notes"
								label="Note"
								variant="outlined"
								multiline
								rows={5}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h5">Galleria</Typography>
							<Controller
								name="gallery"
								control={control}
								render={(properties) => {
									return (
										<Dropzone
											onDrop={(acceptedFiles) => {
												uploadFiles(acceptedFiles);
											}}
										>
											{({ getRootProps, getInputProps }) => (
												<AttachmentUploader>
													{isUploadingGallery && <LinearProgress />}
													<figure>
														{((properties.value || []) as string[]).map(
															(picture, index) => (
																<ButtonBase
																	key={picture}
																	onClick={(event) => {
																		event.preventDefault();

																		setValue("gallery", [
																			...properties.value.slice(0, index),
																			...properties.value.slice(index + 1),
																		]);
																	}}
																>
																	<div>
																		<Trash />
																	</div>
																	<img src={picture} alt="" />
																</ButtonBase>
															),
														)}
													</figure>
													<div {...getRootProps()}>
														<input {...getInputProps()} />
														<Box mt={2}>
															<Typography>
																Trascinare le IMMAGINI che si vogliono mostrare
																nell&apos;articolo all&apos;interno di questo
																spazio per allegarle nella galleria.
															</Typography>
														</Box>
														<Button
															color="primary"
															size="large"
															variant="outlined"
															fullWidth
														>
															Clicca qui per caricare le immagini
														</Button>
													</div>
												</AttachmentUploader>
											)}
										</Dropzone>
									);
								}}
							/>
						</Grid>
					</Grid>
				</main>
			</form>
			<DevTool control={control} />
		</Container>
	);
};

export default PlaceEditor;
