import {
	createMuiTheme,
	CssBaseline,
	ThemeProvider as MuiThemeProvider,
} from "@material-ui/core";
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components/macro";

import { firebase } from "./config/firebase";
import { getCurrentTheme } from "./config/theme";
import reportWebVitals from "./reportWebVitals";
import Routes from "./routes";

const App = () => {
	console.log(firebase);
	const currentTheme = createMuiTheme(getCurrentTheme("light"));

	return (
		<MuiThemeProvider theme={currentTheme}>
			<CssBaseline />
			<ThemeProvider theme={currentTheme}>
				<Routes />
			</ThemeProvider>
		</MuiThemeProvider>
	);
};

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.querySelector("#root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
